
import requests from '../../../request'

const baseUrl = '/stock/product';
const region = 'cloud-api';

const ContentTypeEnum = {
    // json
    JSON: 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED: 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    BLOB: 'multipart/form-data;charset=UTF-8',
}
/**
 * 查询商品库存
 */
export function query(params) {
  return requests({
        method: 'get',
      url: baseUrl + '/query',
      params,
    },
    {
      region,
    },
  );
}

/**
 * 导出
 */
export function exportList(params) {
  return requests({
        method: 'get',
      url: baseUrl + '/export',
      params,
    },
    {
      region,
      responseType: ResponseEnum.BLOB,
    },
  );
}

/**
 * 查询批次
 */
export function queryLot(params) {
  return requests({
        method: 'get',
      url: '/stock/lot/query',
      params,
    },
    {
      region,
      contentType: ContentTypeEnum.JSON,
    },
  );
}

/**
 * 导出批次列表
 */
export function exportLotList(params) {
  return requests({
        method: 'get',
      url: '/stock/lot/export',
      params,
    },
    {
      region,
      responseType: ResponseEnum.BLOB,
    },
  );
}